$blue: #00a7ef;

$gray1: #dddddd;
$gray2: #f1f5f7;
$gray3: #343434;
$gray4: #333333;
$gray5: #979797;
$gray6: #9b9b9b;
$gray7: #7d7a77;
$gray8: #4a4a4a;
$gray9: #999999;
$gray10: #6d7278;
$gray11: #d7d7d7;

$white: #ffffff;

$redDefault: red;
$red1: #fb1d09;

$orange1: #f7b500;

$green1: #92c16f;
$green2: #6dd400;

$rights: #4c6278;
$mat-error: #f44336;

$black1: #0000007e;

$password-strength-label: (
  weak: $red1,
  moderate: $orange1,
  strong: $green2,
  veryStrong: $blue,
);
